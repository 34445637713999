import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HamburgerMenu from 'react-hamburger-menu';
import DataContext from '../context/dataContext';
import { handleMenuAnimation } from '../animations/animation';
import { languageOptions, moneyOptions } from '../filteroptions/options';
import {
  handleOpenSearchAnimation,
  handleCloseSearchAnimation,
} from '../animations/animation';

import _ from 'lodash';

const Header = ({ open, handleOpen }) => {
  let history = useHistory();

  // Check Language From Local Storage
  const { t, i18n } = useTranslation();
  let lang = localStorage.getItem('i18nextLng');
  const dataContext = useContext(DataContext);
  const { getLang, getMoneyType, menus } = dataContext;
  useEffect(() => {
    getLang(lang);
    // eslint-disable-next-line
  }, [lang]);
  // Hamburger Menu Open State
  let mainMenu = [], leftMenu = [], rightMenu = [];
  if (menus) {    
    leftMenu = _.orderBy(_.filter(menus, { 'position': 'left'}), ['order'], ['asc']);
    rightMenu = _.orderBy(_.filter(menus, { 'position': 'right'}), ['order'], ['asc']);
    console.warn("leftMenu >> ", leftMenu);
    // sortedMenus.map((item) => {
    //   mainMenu.push(item);
    //   return mainMenu; // I added return
    // });
  }
  const [search, setSearch] = useState(false);
  const handleSearch = () => {
    if (search === false) {
      setSearch(true);
      handleOpenSearchAnimation();
    }
    if (search === true) {
      setSearch(false);
      handleCloseSearchAnimation();
    }
  };
  // Handle Money and Language State
  const [selectedMoney, setSelectedMoney] = useState(moneyOptions[0].label);
  const [selectedLanguage, setSelectedLanguage] = useState(
    languageOptions[0].label
  );
  const handleChangeMoney = (selectedMoney) => {
    setSelectedMoney(selectedMoney);
    getMoneyType(selectedMoney.value);
  };
  const handleChangeLanguage = (selectedLanguage) => {
    setSelectedLanguage(selectedLanguage);
    // Change Current Language
    i18n.changeLanguage(selectedLanguage.value);
    if (history.location.pathname === '/') {
      window.location.reload();
    } else {
      history.push('/');
      window.location.reload();
    }
  };

  return (
    <header id='header'>
      <HamburgerMenu
        className='hamburger-menu'
        isOpen={open}
        menuClicked={() => {
          handleMenuAnimation(open);
          handleOpen();
        }}
        width={18}
        height={15}
        strokeWidth={1}
        rotate={0}
        color='white'
        borderRadius={0}
        animationDuration={0.5}
      />

      <nav className='navigation' id='header-navigation-left'>
        {(leftMenu.length > 0 || rightMenu.length > 0) && (
          <button className='menu-toggle-button'>
            <span></span>
            <span></span>
            <span></span>
          </button>
        )}
        {leftMenu.length > 0 && (
          <ul>
            {leftMenu.map((item) => (
              <li key={item.id}>
                <a href={item.link} target={item.target}>{item.name}</a>
              </li>
            ))}
          </ul>
        )}
        {rightMenu.length > 0 && (
          <ul>
            {rightMenu.map((item) => (
              <li key={item.id}>
                <a href={item.link} target={item.target}>{item.name}</a>
              </li>
            ))}
          </ul>
        )}
      </nav>
      <div id='logo'>
        <NavLink to='/'>
          <img src="/assets/img/ayana-holding.png" alt="" />
        </NavLink>
      </div>
      {/* <button
        type='button'
        id='search-toggle-button'
        className='menu-toggle-button'
        onClick={handleSearch}
        style={{ zIndex: '99999' }}
      >
        <svg className='icon icon-search' id='search-icon'>
          <path
            d='M14.0257133,12.3216547 L14.1240244,12.1905732 C15.0415944,10.8469885 15.5331498,9.30678171 15.5331498,7.76657488 C15.5331498,3.47365796 12.0594918,0 7.76657488,0 C3.47365796,0 0,3.47365796 0,7.76657488 C0,12.0267214 3.50642832,15.5331498 7.76657488,15.5331498 C9.76556673,15.5331498 11.7317882,14.7466612 13.1409136,13.3703061 L13.2719951,13.2392247 L18.5152524,17.8598452 C18.7446449,18.0564673 19.1378892,18.0564673 19.3672817,17.7943045 C19.5639038,17.564912 19.5311335,17.204438 19.301741,16.9422752 L14.0257133,12.3216547 Z M7.73380452,14.5172687 C3.99798369,14.5172687 0.983110744,11.5023957 0.983110744,7.76657488 C0.983110744,4.03075405 3.99798369,1.0158811 7.73380452,1.0158811 C11.4696254,1.0158811 14.4844983,4.03075405 14.4844983,7.76657488 C14.4844983,11.5023957 11.4696254,14.5172687 7.73380452,14.5172687 Z'
            fill='#fff'
            fillRule='nonzero'
          ></path>
        </svg>
        <svg className='icon icon-times' id='search-close-icon'>
          <path
            d='M0.118819902,19.8811052 C0.198033171,19.9603684 0.30155051,20 0.405517924,20 C0.508585188,20 0.612552603,19.9603684 0.691765871,19.8811052 L10.000225,10.573082 L19.3082341,19.8811052 C19.3874474,19.9603684 19.4909647,20 19.5949322,20 C19.6979994,20 19.8019668,19.9603684 19.8811801,19.8811052 C20.0396066,19.7225788 20.0396066,19.465874 19.8811801,19.3082483 L10.573171,10.0002252 L19.8811801,0.691751672 C20.0396066,0.533675606 20.0396066,0.276970884 19.8811801,0.118894819 C19.7227536,-0.0391812471 19.4666607,-0.0396316063 19.3086842,0.118894819 L9.99977496,9.42691797 L0.691315795,0.118894819 C0.532889259,-0.0396316063 0.276796363,-0.0396316063 0.118819902,0.118894819 C-0.0391565586,0.277421243 -0.039606634,0.534125965 0.118819902,0.691751672 L9.42682899,10.0002252 L0.118819902,19.3082483 C-0.039606634,19.4663244 -0.039606634,19.7230291 0.118819902,19.8811052 Z'
            id='times'
            fill='#fff'
            fillRule='nonzero'
          ></path>
        </svg>
      </button> 
      <nav className='navigation' id='header-navigation-right'>
        <div className='select-box-no-border-wrapper'>
          <Select
            value={selectedLanguage}
            onChange={handleChangeLanguage}
            options={languageOptions}
            classNamePrefix='filter'
            placeholder={selectedLanguage}
          />
        </div>
        <div className='select-box-no-border-wrapper'>
          <Select
            onChange={handleChangeMoney}
            options={moneyOptions}
            classNamePrefix='filter'
            placeholder={selectedMoney}
            value={selectedMoney}
          />
        </div>
      </nav> */}
      <div id='header-hover-handler'></div>
    </header>
  );
};

export default Header;
