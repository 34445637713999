import React, { useState } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { phoneOptions } from '../filteroptions/options';
import { InView } from 'react-intersection-observer';
import { handleScroll } from '../animations/animation';
const RegisterForm = () => {
  const { t } = useTranslation();

  const [selectedPhone, setSelectedPhone] = useState('+93');

  // Register Form State
  const [user, setUser] = useState({
    fullname: '',
    email: '',
    phone: '',
  });
  const onChange = (e) => setUser({ ...user, [e.target.name]: e.target.value });
  // Phone State
  const handleChangePhone = (selectedPhone) => {
    setSelectedPhone(selectedPhone);
  };
  // İnfo Handle State
  const [info, setInfo] = useState('');

  // Form Submit
  const handleSubmit = (e) => {
    e.preventDefault();
    setUser({
      fullname: '',
      email: '',
      phone: '',
    });
    setInfo(!e.target.checked);
  };
  return (
    <InView
      onChange={(inView, entry) => {
        handleScroll(inView);
      }}
    >
      <div id='register-form'>
        <div className='container'>
          <h3>Awards & Recognitions</h3>
          <div className='d-flex flex-wrap justify-content-center awards-container'>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-1-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-2-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-3-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-4-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-5-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-6-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-7-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-8-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-9-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-10-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-11-175x150.png" alt=""/>
            </div>
            <div className='p-md-4 p-2'>
              <img src="/assets/img/awards/award-12-175x150.png" alt=""/>
            </div>
          </div>
          <div className='row justify-content-center get-in-touch'>
            <div className='col-lg-5'>
              <div id='get-in-touch'>
                <h6> {t('GET_IN_TOUCH')}</h6>
                <div id='get-in-touch-items'>
                  <a href='tel:+97143708909' className='get-in-touch-item'>
                    <svg className='icon icon-phone'>
                      <path
                        d='M21.4479695,24 C22.0927865,24 22.7322933,23.4896763 22.8753927,22.8609271 L23.9624627,18.1405244 C23.983154,18.0508927 23.9999542,17.9034445 23.9999542,17.8114325 C23.9999542,17.2792731 23.6030205,16.6769831 23.1139832,16.467049 L18.0331312,14.2873241 C17.8806017,14.2223206 17.6222813,14.1695395 17.4564764,14.1695395 C17.0737794,14.1695395 16.5656072,14.4091829 16.3221643,14.7045371 L14.2549139,17.2311155 C11.4867237,15.8696572 8.13505786,12.5179914 6.77355378,9.74975538 L9.30017796,7.6825507 C9.5954406,7.43915358 9.83512977,6.93111874 9.83512977,6.54846751 C9.83512977,6.38270844 9.78234871,6.12447952 9.71734515,5.9720416 L7.53762024,0.886016796 C7.32626712,0.396933694 6.72232914,0 6.18952886,0 C6.09724212,0 5.94938191,0.0168002152 5.85947558,0.0375372655 L1.13440363,1.12978014 C0.508217953,1.27141465 0,1.90739663 0,2.54942121 L0,2.55477713 C0,14.4279515 9.62359354,24 21.4452229,24 L21.4479695,24 Z M21.4170699,22.4999743 C10.4251973,22.4859207 1.51407934,13.5795178 1.50469502,2.58759935 L6.17355263,1.50945584 L8.32983965,6.54343203 L4.91729022,9.33254241 C7.1860517,14.1701346 9.81581181,16.8092333 14.6721269,19.0874248 L17.4612372,15.6748754 L22.4952134,17.8311624 L21.4170699,22.4999743 Z'
                        id='phone'
                        fill='#fff'
                        fillRule='nonzero'
                      ></path>
                    </svg>
                    {t('CALL')}
                  </a>
                  <a
                    href='https://www.google.com.tr/maps/place/Ayana+holding/@25.2488657,55.3075912,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f43445200bf07:0xe3521a98c6bc3182!8m2!3d25.2488657!4d55.3097799'
                    target="_blank"
                    className='get-in-touch-item'
                  >
                    <svg className='icon icon-location'>
                    <path d="M12 10c-1.104 0-2-.896-2-2s.896-2 2-2 2 .896 2 2-.896 2-2 2m0-5c-1.657 0-3 1.343-3 3s1.343 3 3 3 3-1.343 3-3-1.343-3-3-3m-7 2.602c0-3.517 3.271-6.602 7-6.602s7 3.085 7 6.602c0 3.455-2.563 7.543-7 14.527-4.489-7.073-7-11.072-7-14.527m7-7.602c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602"/>
                    </svg>
                    {t('LOCATION')}
                  </a>
                  <a
                    href='mailto:info@ayanaholding.com'
                    className='get-in-touch-item'
                  >
                    <svg className='icon icon-mail'>
                      <path
                        d='M29,24 C30.65625,24 32,22.65625 32,21 L32,3 C32,1.34375 30.65625,0 29,0 L3,0 C1.34375,0 0,1.34375 0,3 L0,21 C0,22.65625 1.34375,24 3,24 L29,24 Z M16,16 C14.5499878,16.0250244 12.4625244,14.0062256 11.4124756,13.1687622 C5.32501221,8.33752441 3.36877441,6.74377441 2,5.58752441 L2,3 C2,2.45001221 2.45001221,2 3,2 L29,2 C29.5499878,2 30,2.45001221 30,3 L30,5.58752441 C28.6312256,6.74377441 26.6749878,8.33752441 20.5875244,13.1687622 C19.53125,14.0062256 17.4500122,16.0250244 16,16 Z M29,22 L3,22 C2.45001221,22 2,21.5499878 2,21 L2,8.1875 C3.42498779,9.35626221 5.67498779,11.1624756 10.1687622,14.7312622 C11.4500122,15.7562256 13.7125244,18.0125122 16,18 C18.2750244,18.0187378 20.5187378,15.78125 21.8312378,14.7312622 C26.3250122,11.1624756 28.5750122,9.35626221 30,8.1875 L30,21 C30,21.5499878 29.5499878,22 29,22 Z'
                        id='mail'
                        fill='#fff'
                        fillRule='nonzero'
                      ></path>
                    </svg>
                    {t('MAIL')}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </InView>
  );
};

export default RegisterForm;
